<template>
	<div>
		<canvas ref="chart" :style="{'height': height + 'px'}"></canvas>
	</div>
</template>

<script>
	import { Chart, registerables } from 'chart.js';
	Chart.register(...registerables);

	export default ({
		props: [
			'data',
			'height',
			'xaxis',
			'yaxis'
		],
		watch: {
			data: function() {
				this.chart.destroy();
				this.renderChart();
			}
		},
		
		data(){
			return {
				chart: null,
			} ;
		},
		methods: {
			renderChart: function(){
    		let ctx = this.$refs.chart.getContext("2d");
			this.chart = new Chart(ctx, {
				type: "bar",
				data: this.data,
     			options: {		
					layout: {
						padding: {
							top: 30,
							right: 5,
							left: 5,
							bottom: 5,
						},
					},
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
							,
						},
					},
					tooltips: {
						enabled: true,
						mode: "index",
						intersect:true
					},
					scales: {
						y: {
							title:{
								display: true,
								text: this.yaxis,
								color: "#D9D9D9",
								font: {
									size: 18,
									lineHeight: 2.0,
									weight: '600',
									family: "Open Sans",
								},

							},
							grid: {
								display: true,
								color: "rgba(239, 239, 240, .4)",
								zeroLineColor: "#D9D9D9",
								borderDash: [6],
								borderDashOffset: [6],
							},
							ticks: {
								suggestedMin: 0,
								suggestedMax: 1000,
								display: true,
								color: "#D9D9D9",
								font: {
									size: 14,
									lineHeight: 1.5,
									weight: '600',
									family: "Open Sans",
								},
							},
						},
						x: {
							title:{
								display: true,
								text: this.xaxis,
								color: "#D9D9D9",
								font: {
									size: 18,
									lineHeight: 2.0,
									weight: '600',
									family: "Open Sans",
								},

							},
							grid: {
								display: false,
							},
							ticks: {
								display: true,
								color: "#D9D9D9",
								font: {
									size: 14,
									lineHeight: 1.5,
									weight: '600',
									family: "Open Sans",
								},
							},
						},
					},
				}
			}) ;
		},},
		
		mounted () {
			this.renderChart();
		},
		// Right before the component is destroyed,
		// also destroy the chart.
		beforeDestroy: function () {
			this.chart.destroy() ;
		},
	})

</script>

<style lang="scss" scoped>
</style>