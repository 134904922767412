import request from '@/utils/request'

const dashboardApi = {
    get: '/api/dashboardinfo',
    getattacedproducts: '/api/underattack',
    getsales: '/api/salesovertime',
    gettpproduct: '/api/topproduct/',
    gettopcorr: '/api/topproductcorr',
}

export function getdashboardinfo() {
    return request({
        url: dashboardApi.get,
        method: 'get'
    });
}

export function getattackedproduct() {
    return request({
        url: dashboardApi.getattacedproducts,
        method: 'get'
    });
}

export function getsales(){
    return request({
        url: dashboardApi.getsales,
        method: 'get'

    });
}

export function gettopproduct(num){
    return request({
        url: dashboardApi.gettpproduct + num,
        method: 'get'
    });
}

export function gettopcorr(){
    return request({
        url: dashboardApi.gettopcorr,
        method: 'get'

    });
}