<template>
  <div>
    <canvas ref="chart" :style="{ height: height + 'px' }"></canvas>
  </div>
</template>

<script src="path/to/chartjs/dist/chart.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/hammerjs@2.0.8"></script>
<script src="path/to/chartjs-plugin-zoom/dist/chartjs-plugin-zoom.min.js"></script>

<script>
import { Chart, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
Chart.register(...registerables);
Chart.register(zoomPlugin);

export default {
  props: ["data", "height", "enablelegend", "xaxis", "yaxis"],
  watch: {
    data: function () {
      this.chart.destroy();
      this.renderChart();
    },
  },
  methods: {
    renderChart: function () {
      let ctx = this.$refs.chart.getContext("2d");

      this.chart = new Chart(ctx, {
        type: "line",
        data: this.data,
        options: {
          layout: {
            padding: {
              top: 30,
              right: 20,
              left: 20,
              bottom: 15,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            zoom: {
              pan: {
                enabled: true,
				mode: 'xy',
              },
			  zoom:{
				  wheel:{
					  enabled:true,
					  speed: 0.001
				  },
				  mode:'x',
				//   drag:{
				// 	  enabled:true
				//   }
		
			  }
			   
            },
            legend: {
              display: true,
              labels: {
                color: "rgb(255, 255, 255)",
              },
            },
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: true,
          },
          scales: {
            y: {
              title: {
                display: true,
                text: this.yaxis,
                color: "#D9D9D9",
                font: {
                  size: 18,
                  lineHeight: 2.0,
                  weight: "600",
                  family: "Open Sans",
                },
              },
              grid: {
                display: true,
                color: "rgba(239, 239, 240, .4)",
                zeroLineColor: "#000000",
                borderDash: [6],
                borderDashOffset: [6],
              },
              ticks: {
                suggestedMin: 0,
                // suggestedMax: 1000,
                display: true,
                color: "#D9D9D9",
                font: {
                  size: 14,
                  lineHeight: 1.8,
                  weight: "600",
                  family: "Open Sans",
                },
              },
            },
            x: {
              title: {
                display: true,
                text: this.xaxis,
                color: "#D9D9D9",
                font: {
                  size: 18,
                  lineHeight: 2.0,
                  weight: "600",
                  family: "Open Sans",
                },
              },
              grid: {
                display: true,
                color: "rgba(239, 239, 240, .4)",
                borderDash: [6],
                borderDashOffset: [6],
              },
              ticks: {
                display: true,
                color: "#D9D9D9",
                font: {
                  size: 14,
                  lineHeight: 1.5,
                  weight: "600",
                  family: "Open Sans",
                },
              },
            },
          },
        },
      });
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.renderChart();
  },
  // Right before the component is destroyed,
  // also destroy the chart.
  beforeDestroy: function () {
    this.chart.destroy();
  },
};
</script>

<style lang="scss" scoped>
</style>