<template>
<a-card :bordered="false" class="dashboard-bar-line header-solid">
    <template #title>
      <h6>Correlated Products</h6>
      <p>What else products are bought together for top 5 selling products</p>
    </template>
  <a-table :columns="columns" :data-source="data" v-show="data.length">
  </a-table>
  </a-card>
</template>

<script>

import {gettopcorr} from '@/api/dashboard';

const columns = [
  {
    title: 'Top Product ID',
    dataIndex: 'id1',
  },
  {
    title: 'Products Bought Together',
    dataIndex: 'id2',
  },
  {
    title: 'Possibility of Crossselling',
    dataIndex: 'percen',
  },
];

const data = []

export default {
  data() {
    return {
      data,
      columns,
    };
  },
  mounted() {
    // function: dashboard-08
    gettopcorr().then(data=>{
      this.data = data
    })
  }

};
</script>