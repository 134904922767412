<template>

	<!-- Active Users Card -->
	<a-card :bordered="false" class="dashboard-bar-chart">
		<template #title>
     	 <h6>Top selling products</h6>
   		 <p>sales than last week <span class="text-success">+23%</span></p>
   		</template>
		<chart-bar  :height="300" :data="barChartData"></chart-bar>
		<a-form :form="form" @submit="handleSubmit">
                <a-form-item label="How many top products do you want to see">
                    <a-input
                    v-decorator="[
                        'num'
                    ]"
                    />
                </a-form-item>
                <a-button type="primary" html-type="submit">
                    Apply changes
                </a-button>
            </a-form>
	</a-card>
	<!-- Active Users Card -->

</template>

<script>

	// Bar chart for "Active Users" card.
	import ChartBar from '../Charts/ChartBar' ;
	import {gettopproduct} from '@/api/dashboard';

	export default ({
		components: {
			ChartBar,
		},
		props: {
			sales: {
				type: Array,
				default: () => [],
			},
			product: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Data for bar chart.
				barChartData: {},
				form: this.$form.createForm(this,{name: 'number of top products'}),
				numofproduct: 10
			}
		},
		mounted(){
			// function: dashboard-04
			gettopproduct(this.numofproduct).then(data=>{
            for (let i = 0; i < data.length; i++) {
				this.sales.push(data[i]['sales'])
				this.product.push(data[i]['productname'])
				this.barChartData= {
					labels: this.product,
					datasets: [{
						label: "Sales",
						backgroundColor: ['#9A89FF','#00D69E'],
						borderWidth: 0,
						borderSkipped: false,
						borderRadius: 6,
						data: this.sales,
						maxBarThickness: 20,
					}, ],
				}
           }

    })
		},
		methods:{
			// function: dashboard-05
			handleSubmit(e){
                e.preventDefault();
                this.form.validateFields((err,values)=>{
                    if (!err){
                        this.numofproduct = values.num
						this.sales = []
						this.product = []
						gettopproduct(this.numofproduct).then(data=>{
            for (let i = 0; i < data.length; i++) {
				this.sales.push(data[i]['sales'])
				this.product.push(data[i]['productname'])
				this.barChartData= {
					// labels: ["Vetemjöl", "Latte", "Citron", "Brownies", "Nötmix"],
					labels: this.product,
					datasets: [{
						label: "Sales",
						backgroundColor: ['#9A89FF','#00D69E'],
						borderWidth: 0,
						borderSkipped: false,
						borderRadius: 6,
						// data: [900, 850, 620, 600, 500],
						data: this.sales,
						maxBarThickness: 20,
					}, ],
				}
           }

    })
                    }
                });
            },
		}
		
		
	})

</script>

