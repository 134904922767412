<template>

	<a-card :bordered="false" class="dashboard-bar-line header-solid">
		<template #title>
			<h6>Sales Overview</h6>			
			<p>than last year <span class="text-success">+20%</span></p>	
		</template>
		<!-- <template #extra>
			<a-badge color="primary" class="badge-dot-primary" text="Traffic" />
			<a-badge color="primary" class="badge-dot-secondary" text="Sales" />
		</template> -->
		<chart-line :height="500" :data="lineChartData"></chart-line>
	</a-card>

</template>

<script>

	// Bar chart for "Active Users" card.
	import ChartLine from '../Charts/ChartLine' ;
	import {getsales} from  '@/api/dashboard';
     

	export default ({
		components: {
			ChartLine,
		},
		props: {
			y: {
				type: Array,
				default: () => [],
			},
			time: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Data for line chart.
				lineChartData: {},
			}
		},
		mounted() {
			//function: dashboard-03
    		getsales().then(data=>{
            for (let i = 0; i < data.length; i++) {
				this.y.push(data[i]['sales'])
				this.time.push(data[i]['day'].substring(0,10))
				this.lineChartData= {
					// labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
					labels: this.time,
					datasets: [{
						label: "Sales",
						tension: 0.4,
						fill: {
                target: 'origin',
                above: "rgba(0, 215, 124, 0.5)",   // Area will be red above the origin
                below: 'rgba(0, 0, 255)'    // And blue below the origin
              },
						
						pointRadius: 3,
						borderColor: "#00D69E",
						borderWidth: 3,
						// data: [25000, 40000, 30000, 22000, 50000, 25000, 40000, 23000, 50000],
						data:this.y,
						maxBarThickness: 6

					},
					],
				}
           }
    })

  }
	})

</script>

