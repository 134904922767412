<template>
<a-card :bordered="false" class="dashboard-bar-line header-solid">
    <template #title>
      <h6>Products under attack</h6>
      <p>Products under price attack from competitors</p>
    </template>
  <a-table :columns="columns" :data-source="data" v-show="data.length">
    <a slot="name" slot-scope="text, record"><router-link :to="{path:'/productdetail', query:{productid: record.productKey}}">{{ text }}</router-link></a>
  </a-table>
  </a-card>
</template>

<script>

import {getattackedproduct} from '@/api/dashboard';

const columns = [
  {
    title: 'Product name',
    dataIndex: 'productname',
    key: 'product',
    // slots: { title: 'customTitle'},
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Our price',
    dataIndex: 'ourprice',
    key: 'ourprice',
  },
  {
    title: 'Lowest price',
    dataIndex: 'minprice',
    key: 'minprice',
  },
  {
    title: 'Price difference',
    dataIndex: 'pricediff',
    key: 'pricediff',
    sorter: (a, b) => a.pricediff - b.pricediff,
  },
  {
    title: 'Competitor',
    dataIndex: 'competitor',
    key: 'competitor',
  },
  {
    title: 'Checked time',
    dataIndex: 'timestamp',
    key: 'timestamp',
    sorter: (a, b) => new Date(a.timestamp)- new Date(b.timestamp),
  },
];

const data = []

export default {
  data() {
    return {
      data,
      columns,
    };
  },
  mounted() {
    // function-dashboard-02
    getattackedproduct().then(data=>{
      data.forEach(element => {
        element['pricediff'] = (Number(element['ourprice']) - Number(element['minprice'])).toFixed(3);
        element['timestamp'] = new Date(element['timestamp']).toString().substring(0,21);
      });
      this.data = data
    })
  }

};
</script>
