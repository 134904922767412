<template>
  <a-card :bordered="false" class="dashboard-bar-line header-solid">
    <template #title>
      <h6>Correlated Products</h6>
      <p>What else products are bought together for top selling products</p>
    </template>
    <a-dropdown>
      <template #overlay>
        <a-menu @click="chooseproduct">
          <template v-for="itm in topporducts">
            <a-menu-item :key="itm.id"
              ><UserOutlined />{{ itm.product }}</a-menu-item
            >
          </template>
        </a-menu>
      </template>
      <a-button>
        Top product: {{chosenproduct}}
      </a-button>
    </a-dropdown>
    <chart-polar :height="300" :data="PolarChartData"></chart-polar>
  </a-card>
</template>

<script>
import { gettopcorr } from "@/api/dashboard";
import ChartPolar from "../Charts/ChartPolar.vue";

export default {
  components: {
    ChartPolar,
  },
  data() {
    return {
      topporducts: [],
      data: [],
      chosenproduct: [],
      labels: [],
      possi: [],
      PolarChartData: {},
    };
  },
  methods: {
    // function: dashboard-10
    chooseproduct(v, o) {
      this.labels = []
      this.possi = []
      for (let i = 0; i < this.topporducts.length; i++){
        if(this.topporducts[i]['id']==v.key){
          this.chosenproduct = this.topporducts[i]['product'];
        }
      }
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i]["id1"] == this.chosenproduct) {
          this.labels.push(this.data[i]["id2"]);
          this.possi.push(this.data[i]["percent"]);
        }
        this.PolarChartData = {
          labels: this.labels,
          datasets: [
            {
              data: this.possi,
              backgroundColor: [
                "rgba(0, 215, 124, 0.7)",
                "rgba(154, 137, 255, 0.7)",
                "rgba(255, 0, 93, 0.7)",
                "rgba(255, 182, 54, 0.7)",
              ],
            },
          ],
        };
      }
    },
  },
  mounted() {
    // function: dashboard-09
    gettopcorr().then((data) => {
      this.data = data;
      for (let i = 0; i < this.data.length; i++) {
        if (!this.topporducts.some((e) => e.product == data[i]["id1"])) {
          this.topporducts.push({ id: i, product: data[i]["id1"] });
        }
      }
    });
  },
};
</script>