<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		<!-- Counter Widgets -->
		<a-row :gutter="24">
			<a-col :span="24" :lg="12" :xl="6" class="mb-24" v-for="(stat, index) in stats" :key="index">
				<!-- Widget 1 Card -->
				<WidgetCounter
					:title="stat.title"
					:value="stat.value"
					:prefix="stat.prefix"
					:suffix="stat.suffix"
					:icon="stat.icon"
					:status="stat.status"
					:link="stat.link"
				></WidgetCounter>
				<!-- / Widget 1 Card -->
			</a-col>
		</a-row>
		<!-- / Counter Widgets -->
		<a-row :gutter="24" type="flex" align="stretch">


			<!-- Timeline -->
			<a-col :span="24" :lg="24" class="mb-24">


				<!-- Orders History Timeline Card -->
				<CardTable></CardTable>
				<!-- / Orders History Timeline Card -->

			</a-col>
			<!-- / Timeline -->
		</a-row>

		<!-- Charts -->
		<a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" :lg="24" class="mb-24">
				
				<!-- Sales Overview Card -->
				<CardLineChart></CardLineChart>
				<!-- / Sales Overview Card -->

			</a-col>
		</a-row>
		<!-- / Charts -->

	

		<!-- Cards -->
		<a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" :lg="12" class="mb-24">

				<!-- Active Users Card -->
				<CardBarChart></CardBarChart>
				<!-- Active Users Card -->

			</a-col>

			<a-col :span="24" :xl="6" class="mb-24">

				<!-- Information Card 2 -->
				<CardPieChart></CardPieChart>
				<!-- / Information Card 2 -->

			</a-col>
			<a-col :span="24" :lg="6" class="mb-24">
				
				<!-- Projects Table Card -->
				<CardRadarChart graphtitle="Campaign Efficiency"> </CardRadarChart>
				<!-- / Projects Table Card -->
				
			</a-col>
		</a-row>
		<!-- / Cards -->
		
		<a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" :lg="16" class="mb-24">
				
				<!-- Sales Overview Card -->
				<CardCorrelationTable></CardCorrelationTable>
				<!-- / Sales Overview Card -->

			</a-col>
			<a-col :span="24" :lg="8" class="mb-24">
				
				<!-- Sales Overview Card -->
				<CardPolar></CardPolar>
				<!-- / Sales Overview Card -->

			</a-col>
		</a-row>





	</div>
</template>

<script>

	// Bar chart for "Active Users" card.
	import CardBarChart from '../components/Cards/CardBarChart' ;

	// Line chart for "Sales Overview" card.
	import CardLineChart from '../components/Cards/CardLineChart' ;

	// Counter Widgets
	import WidgetCounter from '../components/Widgets/WidgetCounter' ;

	// "Projects" table component.
	import CardProjectTable from '../components/Cards/CardProjectTable' ;

	// Order History card component.
	import CardOrderHistory from '../components/Cards/CardOrderHistory' ;

	import CardRadarChart from '../components/Cards/CardRadarChart' ;
	import CardPieChart from '../components/Cards/CardPieChart' ;
	import CardTable from '../components/Cards/CardTable' ;
	import CardCorrelationTable from '../components/Cards/CardCorrelationTable' ;
	import CardPolar from '../components/Cards/CardPolar' ;
	
	// Information card 1.
	 import CardInfo from '../components/Cards/CardInfo' ;

	// Information card 2.
	 import CardInfo2 from '../components/Cards/CardInfo2' ;

	import {getdashboardinfo} from '@/api/dashboard';

	// "Projects" table list of columns and their properties.
	const tableColumns = [
		{
			title: 'COMPANIES',
			dataIndex: 'company',
			scopedSlots: { customRender: 'company' },
			width: 300,
		},
		{
			title: 'MEMBERS',
			dataIndex: 'members',
			scopedSlots: { customRender: 'members' },
		},
		{
			title: 'BUDGET',
			dataIndex: 'budget',
			class: 'font-bold text-muted text-sm',
		},
		{
			title: 'COMPLETION',
			scopedSlots: { customRender: 'completion' },
			dataIndex: 'completion',
		},
	];

	// "Projects" table list of rows and their properties.
	const tableData = [
		{
			key: '1',
			company: {
				name: 'Soft UI Shopify Version',
				logo: 'images/logos/logo-shopify.svg',
			},
			members: [ "images/face-1.jpg", "images/face-4.jpg", "images/face-2.jpg", "images/face-3.jpg", ],
			budget: '$14,000',
			completion: 60,
		},
		{
			key: '2',
			company: {
				name: 'Progress Track',
				logo: 'images/logos/logo-atlassian.svg',
			},
			members: [ "images/face-4.jpg", "images/face-3.jpg", ],
			budget: '$3,000',
			completion: 10,
		},
		{
			key: '3',
			company: {
				name: 'Fix Platform Errors',
				logo: 'images/logos/logo-slack.svg',
			},
			members: [ "images/face-1.jpg", "images/face-2.jpg", "images/face-3.jpg", ],
			budget: 'Not Set',
			completion: {
				label: '100',
				status: 'success',
				value: 100,
			},
		},
		{
			key: '4',
			company: {
				name: 'Launch new Mobile App',
				logo: 'images/logos/logo-spotify.svg',
			},
			members: [ "images/face-1.jpg", "images/face-2.jpg", ],
			budget: '$20,600',
			completion: {
				label: '100',
				status: 'success',
				value: 100,
			},
		},
		{
			key: '5',
			company: {
				name: 'Add the New Landing Page',
				logo: 'images/logos/logo-jira.svg',
			},
			members: [ "images/face-1.jpg", "images/face-4.jpg", "images/face-2.jpg", "images/face-3.jpg", ],
			budget: '$4,000',
			completion: 80,
		},
		{
			key: '6',
			company: {
				name: 'Redesign Online Store',
				logo: 'images/logos/logo-invision.svg',
			},
			members: [ "images/face-1.jpg", "images/face-4.jpg", "images/face-3.jpg", ],
			budget: '$2,000',
			completion: {
				label: 'Cancelled',
				status: 'exception',
				value: 100,
			},
		},
	];

	export default ({
		components: {
			CardBarChart,
			CardLineChart,
			WidgetCounter,
			CardProjectTable,
			CardOrderHistory,
			CardInfo,
			CardInfo2,
			CardRadarChart,
			CardPieChart,
			CardTable,
			CardCorrelationTable,
			CardPolar,
			
		},
		data() {
			return {

				// Associating table data with its corresponding property.
				tableData,

				// Associating table columns with its corresponding property.
				tableColumns,

				// Counter Widgets Stats
				stats:[],
			}
		},
		mounted() {
			// function: dashboard-01
			getdashboardinfo().then(data=>{
					this.stats = [
						{
							title: "Products",
							value: data.productcount,
							prefix: "",
							suffix: "",
							icon: "list",
							link: "products"
						},
						{
							title: "Competitors",
							value: data.competitorcount,
							suffix: "",
							icon: "sitemap",
							link: "competitors"
						},
						{
							title: "Last check",
							prefix: '',
							value: data.lastupdate + ' hours ago',
							status: "danger",
							suffix: "hours ago",
							icon: "clock",
						},
						{
							title: "Goals",
							value: data.goalcount,
							prefix: "",
							suffix: "",
							icon: "flag",
							link: "goals"
						},
					]
			})
		}
	})

</script>

<style lang="scss">
</style>